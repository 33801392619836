<template>
  <div>
    <el-dialog :close-on-click-modal="false" destroy-on-close title="导出设置" v-model="dialogFormVisibleManager"  class="button_bg">
      <el-form :model="temp" class="print_box" label-width="100px" label-position="right">
        <!-- <el-form-item label="科目:" v-if="this.name == book_diary_account">
          <subject-list-all v-model:subjectId="listQuery1.subjectId" :codes="$findCode(this.code)" clear></subject-list-all>
          <span style="color:red;font-size: 20px;vertical-align: middle;">*</span><span style="color:red">不选默认打印全部</span>
        </el-form-item> -->
        <el-form-item label="" v-if="name == 'book_voucher'">
          <span style="color:red;font-size: 20px;vertical-align: middle;">*</span>
          <span v-if="listIds.length == 0" style="color:red">确定导出全部</span>
          <span v-else style="color:red">确定导出已勾选的</span>
        </el-form-item>


        <el-form-item label="科目:" v-if="name != 'book_voucher'">
          <subject-list-all v-model:subjectId="listQuery1.subjectId" clear></subject-list-all>
          <span style="color:red;font-size: 20px;vertical-align: middle;">*</span><span style="color:red">不选默认导出全部</span>
        </el-form-item>
        <el-form-item label-width="42px" v-if="this.name == 'book_subject_balance'">
            是否只显示有金额科目 ：
          <el-switch v-model="not0" size="large" /> 
        </el-form-item>
        <el-form-item label="科目选择：" class="item_row" v-if="name == 'book_detail_account'">
          <el-radio-group v-model="listQuery1.level">
            <el-radio :label="0">默认</el-radio>
            <el-radio :label="1">一级</el-radio>
            <el-radio :label="2">二级</el-radio>
            <el-radio :label="3">三级</el-radio>
            <el-radio :label="4">四级</el-radio>
            <el-radio :label="5">五级</el-radio>
            <el-radio :label="100">最下级科目</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="规则：" class="item_row" v-if="name == 'book_detail_account'">
          <el-radio-group v-model="temp.merge">
            <el-radio :label="0">默认</el-radio>
            <el-radio :label="1">合并</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisibleManager = false" size="small">取消</el-button>
          <el-button type="primary" @click="print()" size="small">确定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { printSetOne,exportBook } from "@/api/printSet";
export default {
  name:'exportFile',
  props: {
    name: {
      type: String,
      default: ""
    },
    listQuery1: {
      type: Object,
    },
    code: {
      type: Array,
    },
    listIds:{
      type:Array,
      default: []
    },
  },
  data () {
    return {
      temp:{
        level:0,
        radio:0,
        merge:0,
      },
      not0:false,
      dialogFormVisibleManager: false
    };
  },
  methods: {
    init(wbStatatus){
      if(wbStatatus == 'wb'){
        this.optionType = '1'
      }else if(wbStatatus == 'sl'){
        this.optionType = '2'
      }else{
        this.optionType = ''
      }
      // this.initTemp()
      if(this.name == 'book_multi_column'){
        this.print()
        return
      }
      this.dialogFormVisibleManager = true
      printSetOne({type:'excel', bookNames:this.name}).then(res=>{
        this.temp = res.data.data.info?res.data.data.info:this.temp
        this.temp.type = "excel"
      })
    },
    // 导出
    print(){
      let params = Object.assign({}, this.temp)
      params.query = this.listQuery1
      params.comIds = [this.listQuery1.comId]
      params.bookNames = [this.name]
      params.paperType = "A4-H"
      params.not0 = this.not0
      if (this.listIds) {
        params.ids = this.listIds
      }
      if(this.listQuery1.endTime < this.listQuery1.beginTime) {
        this.$message({
          showClose: true,
          message: '请查看日期起止是否填写正确',
          type: 'warning'
        });
        return
      }
      if(this.name == 'book_subject_balance'){
        params.optionType = this.optionType
      }
      this.dialogFormVisibleManager = false
      exportBook(params).then(res=>{
        if (res.data.data.url) {
          window.open(res.data.data.url); 
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped>

</style>


